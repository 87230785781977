import React, { useState } from "react";
import * as XLSX from "xlsx";
import ExcelDataViewer from "./components/ExcelDataViewer";

function ExcelToQr() {
  const [excelData, setExcelData] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState(["", "", ""]);
  const [savedColumns, setSavedColumns] = useState([]);
  const [showColumns, setShowColumns] = useState(false);

  const changeShowColumns = () => {
    setShowColumns(!showColumns);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Verificar si el archivo es de tipo Excel
    if (file && (file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setExcelData(parsedData);
      };

      reader.readAsBinaryString(file);
    } else {
      alert("Por favor selecciona un archivo de tipo Excel (.xlsx, .xls)");
    }
  };

  const handleColumnSelection = (e, index) => {
    const selectedColumnName = e.target.value;
    const updatedSelectedColumns = [...selectedColumns];
    updatedSelectedColumns[index] = selectedColumnName;
    setSelectedColumns(updatedSelectedColumns);
  };

  const handleSaveColumn = (index) => {
    const selectedColumnData = excelData.map((row) => row[selectedColumns[index]]);
    localStorage.setItem(`savedColumn${index}`, JSON.stringify(selectedColumnData));
    setSavedColumns([...savedColumns, selectedColumns[index]]);
    alert(`Columna ${selectedColumns[index]} guardada en el localStorage`);
  };

  return (
    <div className="m-auto flex flex-col">
      <input type="file" onChange={handleFileChange} className="mx-auto mt-2" />
      {excelData && (
        <div className="m-auto mt-1 mb-1">
          <h3 className="mb-2 text-purple-500 text-center">Selecciona las columnas a guardar:</h3>
          {[0, 1, 2].map((index) => (
            <div key={index} className="flex flex-col items-center mb-2">
              <select onChange={(e) => handleColumnSelection(e, index)} className="bg-purple-200 p-2 rounded-lg mb-1">
                {parseInt(index) === 0 && <option value="">Seleccionar código</option>}
                {parseInt(index) === 1 && <option value="">Seleccionar monto</option>}
                {parseInt(index) === 2 && <option value="">Seleccionar identificador</option>}
                {Object.keys(excelData[0]).map((columnName, columnIndex) => (
                  <option key={columnIndex} value={columnName}>
                    {columnName}
                  </option>
                ))}
              </select>
              <button onClick={() => handleSaveColumn(index)} className="bg-purple-500 text-white px-4 py-2 rounded-md">
                Guardar
              </button>
            </div>
          ))}
        </div>
      )}
      <h3 className="mx-auto text-purple-500 mt-4">Columnas guardadas:</h3>
      <ul className="mx-auto list-disc pl-8">
        {savedColumns.map((column, index) => (
          <li key={index} className="text-purple-500">
            {column}
          </li>
        ))}
      </ul>
      <button onClick={changeShowColumns} className="bg-purple-500 text-white px-4 py-2 rounded-md mt-4">
        {!showColumns ? 'Mostrar' : 'Dejar de mostrar'} columnas del Excel
      </button>
      {showColumns && <ExcelDataViewer excelData={excelData} />}
    </div>
  );
}

export default ExcelToQr;
