import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import Header from "./Header";
import ExelToQr from "./ExelToQr";
import AppTaxiRosa from "./AppTaxiRosa";
import ExcelToQRTaxiRosa from "./components/Taxirosa/ExcelToQRTaxiRosa";
import Dropdown from "./components/HeaderComponents/Dropdown";
import TaxiRosa from "./TaxiRosa";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Header />,
    errorElement: <h1>Error looking route</h1>,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/loadexcel",
        element: <ExelToQr />,
      },
      {
        path: "/qrtaxi",
        element: <ExcelToQRTaxiRosa />,
      },
      {
        path: "/heymoto",
        element: <AppTaxiRosa />,
      },
      {
        path: "/qrs",
        element: <ExcelToQRTaxiRosa />,
      },
      {
        path: "/dropdowns",
        element: <Dropdown />,
      },
      {
        path: "/taxirosa",
        element: <TaxiRosa />,
      },
    ],
  },

  // {
  //   path:'/2',
  //   element: <h1> Mi ruta 3 </h1>,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
