import { Outlet } from "react-router-dom";
import React from "react";
import DefaultHeader from "./components/HeaderComponents/DefaultHeader";


export default function Header() {
  return (
    <div>
      <DefaultHeader/>
      <Outlet></Outlet>
    </div>
  );
}
