import React from 'react';

function ExcelDataViewer({ excelData }) {
  return (
    <div>
      {excelData && (
        <table>
          <thead>
            <tr>
              {Object.keys(excelData[0]).map((columnName, index) => (
                <th key={index}>{columnName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {excelData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(row).map((cellData, cellIndex) => (
                  <td key={cellIndex}>{cellData}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ExcelDataViewer;