import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import ExcelDataViewer from '../ExcelDataViewer';

function ExcelToQRTaxiRosa() {
  const [excelData, setExcelData] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(parsedData);
    };
    
    reader.readAsBinaryString(file);
  };

  const handleColumnSelection = (columnName) => {
    const updatedSelectedColumns = [...selectedColumns];
    const columnIndex = updatedSelectedColumns.indexOf(columnName);
    
    if (columnIndex === -1) {
      updatedSelectedColumns.push(columnName);
    } else {
      updatedSelectedColumns.splice(columnIndex, 1);
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const handleColumnSelectionChange = (e) => {
    const selectedColumnName = e.target.value;
    setSelectedColumn(selectedColumnName);
  };

  const handleSaveColumn = () => {
    if (selectedColumn !== null && selectedColumn !== '') {
      const selectedColumnData = excelData.map(row => row[selectedColumn]);
      localStorage.setItem('savedColumnTaxirosa', JSON.stringify(selectedColumnData));
      alert('Columna guardada en el localStorage');
    } else {
      alert('Por favor selecciona una columna');
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {excelData && (
        <div>
          <div>
            <h3>Selecciona la columna a guardar:</h3>
            <select onChange={handleColumnSelectionChange}>
              <option value="">Selecciona una columna</option>
              {Object.keys(excelData[0]).map((columnName, index) => (
                <option key={index} value={columnName}>{columnName}</option>
              ))}
            </select>
          </div>
          <button onClick={handleSaveColumn}>Guardar Columna</button>
        </div>
      )}


<ExcelDataViewer excelData= {excelData } />
    </div>
  );
}

export default ExcelToQRTaxiRosa;
