import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Función classNames sin cambios
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Dropdown({ goTo }) {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowDirection, setArrowDirection] = useState("down");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setArrowDirection(isOpen ? "down" : "up"); // Cambiar la dirección del icono
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={toggleDropdown}
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={`-mr-1  h-5 w-5 text-gray-400 transform transition-transform duration-300 ${
              arrowDirection === "up" ? "rotate-180 " : "" // Rotar el icono si está hacia arriba
            }`}
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Agregar clase 'hidden' si 'isOpen' es falso */}
      <div
        className={`right-0 z-10 mt-2 absolute w-[178px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className="py-1">
          <button
            className="block px-4 py-2 text-sm text-gray-700"
            onClick={() => goTo("./")}
          >
            {" "}
            Generador de QR{" "}
          </button>

          <button
            className="block px-4 py-2 text-sm text-gray-700"
            onClick={() => goTo("./heymoto")}
          >
            {" "}
            heymoto{" "}
          </button>

          <button
            className="block px-4 py-2 text-sm text-gray-700"
            onClick={() => goTo("./loadexcel")}
          >
            {" "}
            Cargar dede Exel{" "}
          </button>

          <button
            className="block px-4 py-2 text-sm text-gray-700"
            onClick={() => goTo("./qrs")}
          >
            {" "}
            Exel (en produccion){" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
